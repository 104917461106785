import styled from '@emotion/styled'

export const CricketWrapper = styled.div`
	display: flex;
	position: absolute;
	top: 131px;
	right: 0px;

	canvas {
		width: 150px;
		height: 150px;
		cursor: pointer;
	}

	.hidden {
		visibility: hidden;
	}

	.roll-loop, .roll-outro {
		position: absolute;
		top: 0;
		left: 0;

		.hidden {
			display: none;
		}
	}
`;